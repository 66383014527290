import fetch from 'isomorphic-fetch'
import {objectMap} from "../../../lib/utils/get-table-transform-schema-and-form-data";

/**
 * Форматирует число в рублях 
 * @param price 
 * @returns 
 */
export function formatPrice(price: number): string {
  const formatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB'
  })
  return formatter.format(price).replace('₽', '')
}

function createHeaders(headers) {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...headers,
  }
}

export async function fetchV2(path: string, options: any = {}): Promise<Record<string, any>> {
  try {
    const response = await fetch(path, {
      credentials: 'include',
      ...options,
      headers: createHeaders(options.headers),
    })

    return {
      status: response.status,
      body: await response.json()
    }

  } catch (e) {
    console.error('fetch failed', e)
    throw e
  }
}

export async function fetchV2_1(path: string, options: any = {}): Promise<Record<string, any>> {
  try {
    const response = await fetch(path, {
      credentials: 'include',
      ...options,
      headers: createHeaders(options.headers),
    })

    if(response.status === 200)
      return response.json()

    throw new Error(await response.json())

  } catch (e) {
    console.error('fetch failed', e)
    throw e
  }
}



/**
 * Переименовывает ключи в списках, необходим для вывода селектов
 * @param list
 * @param newKeys
 */
export const renameKeys = (list, newKeys) => {
  const keyValues = Object.keys(list).map(key => {
    const newKey = newKeys[key] || key
    return { [newKey]: list[key] }
  })
  return Object.assign({}, ...keyValues)
}

/**
 * Устанавливает списки в схеме
 * @param enums
 * @param schema
 */
export const setEnumFields = (enums, schema) => {
  schema.properties = objectMap(schema['properties'], ([key, value]) => {
    if (enums[key]) {
      const enumItems = enums[key].map(a => a.id)
      const oneOf = enums[key].map(a => {
        return {
          const: a.id,
          title: a.value,
        }
      })
      value = { ...value, enum: enumItems, oneOf }
    }
    return [key, {...value}]
  })
}

/**
 * Преобразовывает объект в строку для GET запроса
 *
 * @param obj
 */
export const objectToUrl = (obj) => {
  const searchParams = new URLSearchParams()

  for (const key of Object.keys(obj)) {
    const value = obj[key]

    if (Array.isArray(value)) {
      for (const item of value) {
        searchParams.append(key, item)
      }
    } else {
      searchParams.append(key, value.toString())
    }
  }
  return searchParams.toString()
}

/**
 * Функция для склонения слова в зависимости от числа.
 *
 */
export const declension = (number, singular, plural1, plural2) => {
  const lastDigit = number % 10
  const lastTwoDigits = number % 100

  return (lastTwoDigits >= 11 && lastTwoDigits <= 14) || lastDigit === 0 || lastDigit >= 5
    ? plural2
    : lastDigit === 1
      ? singular
      : plural1
}
/**
 * Вставить значение в массив после последнего вхождения value
 */
export function insertAfterLastOccurrence<T>(arr: T[], value: T, newValue: T): T[] {
  const lastIndex = arr.lastIndexOf(value);
  if (lastIndex !== -1) {
      arr.splice(lastIndex + 1, 0, newValue);
  }
  return arr;
}
