import { isValidCancelBKP } from '../lib/isValidCancelBKP'
import { isValidCancelAKP } from '../lib/isValidCancelAKP'
import { isValidWorkOrder } from '../lib/isValidWorkOrder'
import { isValidContracts } from '../lib/isValidContracts'
import { IOrderByMonthItemCalc, IOrderByMonthItemTotal, OrdersForUnit } from '../types/types'
import { formatPrice } from '../../../utils'

/** Вычисляет статистику заказов за указанный период для менеджера */
export const calcOrders = (
  stats: OrdersForUnit,

  selectedMonths: string[],
  selectedYears: string[],
  manager: string,
  office?: string,
): IOrderByMonthItemCalc => {
  const orders = stats.CO
  const ordersByPrepaymentCount = stats.Prepayment
  const ordersByMeasurementsCount = stats.Measurements
  const ordersByCaseDateFactCount = stats.CaseDateFact

  const co: number = orders.length
  const cancel_before_kp: number = orders.filter(i => isValidCancelBKP(i)).length
  const cancel_after_kp: number = orders.filter(i => isValidCancelAKP(i)).length
  const workOrders: number = orders.filter(i => isValidWorkOrder(i)).length
  const workOrdersSum: number = orders.filter(i => isValidWorkOrder(i)).reduce((acc, val ) => {
    return acc + (val['products-price'] || 0) 
  }, 0)
  
  
  const measurements: number = ordersByMeasurementsCount

  const contracts: number = ordersByPrepaymentCount

  // Сумма продаж
  const _sum: number = orders
    .filter(i => isValidContracts(i, selectedMonths, selectedYears))
    .reduce((acc, i) => acc + i['products-price'], 0)
  // let percent_md: number = (ordersByPrepaymentCount / measurements) * 100
  let percent_cc: number = (ordersByPrepaymentCount / co) * 100
  // СЧ продаж
  let average_score: number = _sum / ordersByPrepaymentCount || 0

  // if (!Number.isFinite(percent_md)) {
  //   percent_md = 0
  // }
  if (!Number.isFinite(percent_cc)) {
    percent_cc = 0
  }
  return {
    manager: manager,
    office: office,
    cancel_before_kp,
    co,
    cancel_after_kp,
    workOrders,
    measurements,
    contracts,
    workOrdersSum,
    // percent_md,
    percent_cc,
    average_score,
    sum: _sum,
    _prepaymentCount: ordersByPrepaymentCount,
    _caseDateFactCount: ordersByCaseDateFactCount,
  }
}

export class OrderByMonthItemCalc implements IOrderByMonthItemCalc {
  manager: string
  office?: string
  co = 0
  cancel_before_kp = 0
  cancel_after_kp = 0
  workOrders = 0
  measurements = 0
  contracts = 0
  workOrdersSum = 0
  
  _prepaymentCount = 0
  _caseDateFactCount = 0
  // Рассчитываются методом
  // percent_md = 0
  percent_cc = 0
  average_score = 0
  sum = 0
  constructor(manager = '', office: string | undefined) {
    this.manager = manager
    this.office = office
  }
  _add(item: IOrderByMonthItemCalc) {
    this.co += item.co
    this.cancel_before_kp += item.cancel_before_kp
    this.cancel_after_kp += item.cancel_after_kp
    this.workOrders += item.workOrders
    this.workOrdersSum += item.workOrdersSum
    this.measurements += item.measurements
    this.contracts += item.contracts
    this._prepaymentCount += item._prepaymentCount
    this._caseDateFactCount += item._caseDateFactCount
    this.sum += item.sum
  }

  addMany(items: IOrderByMonthItemCalc[]) {
    items.forEach(item => {
      this._add(item)
    })
    this.calculatePercents()
    return this
  }
  calculatePercents() {
    // if (this.measurements) {
    //   this.percent_md = +((this.contracts / this.measurements) * 100).toFixed(2)
    // }
    if (this.co) {
      this.percent_cc = +((this._prepaymentCount / this.co) * 100).toFixed(2)
    }

    // СЧ продаж
    if (this._prepaymentCount) {
      this.average_score = +(this.sum / this._prepaymentCount).toFixed()
    }
  }
  toTotal(): IOrderByMonthItemTotal{
    
    return {...this,
      // percent_md: this.percent_md + '%',
      percent_cc: this.percent_cc + '%',
      
      workOrdersSum: formatPrice(Math.floor(this.workOrdersSum)),
      average_score: formatPrice(Math.floor(this.average_score)),
      sum: formatPrice(Math.floor(this.sum))
    }
  }
}

export const sumStatistics = (stats: IOrderByMonthItemCalc[], manager = 'Всего', office: string | undefined = undefined) => {
  return new OrderByMonthItemCalc(manager, office).addMany(stats)
}
