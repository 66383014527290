import {isDateInRange} from "./isDateInRange";

const validStages = ['Договор','Производство', 'Доставка','Монтаж', 'Выполнено']
export const isValidOrderByManager = (order, selectedMonths: string[], selectedYears: string[]): boolean => {
    return (validStages.includes(order["stage-code"])
        && order["contract-sum"]
        && order["production-order-number"]
        && order["prepayment_date"]
        && isDateInRange(order["case-date-fact"], selectedMonths, selectedYears)
    )
}