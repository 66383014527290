import { ColumnsType } from 'antd/es/table'

export class OrdersForUnit {
  managerName?: string
  Prepayment = 0
  Measurements = 0
  CO = [] as OrderItemResponse[]
  CaseDateFact = 0
}
export interface IFilterOption {
  id: string | number
  value: string
}

export interface IOrderByUnitItem {
  caseDate: string
  contractNum: string
  days: number
  orderDate: string
  sum: number
  manager: string
  managerId: string
  office: string
  key: string
}
export class OrderByUnitItem implements IOrderByUnitItem {
  caseDate = ''
  contractNum = '' 
  days = 0
  orderDate= ''
  sum = 0
  manager
  managerId= ''
  office
  key= ''
  constructor(manager='', office = ''){
    this.manager = manager
    this.office = office
  }
}
// Суммарная статистика для менеджера за месяц
export interface IOrderByMonthItem {
  manager: string
  office?: string
  co: number
  cancel_before_kp: number
  cancel_after_kp: number
  workOrders: number
  measurements: number
  contracts: number
  workOrdersSum: number | string
}

export interface IOrderByMonthItemCalc extends IOrderByMonthItem {
  workOrdersSum: number
  sum: number
  _prepaymentCount: number
  _caseDateFactCount: number
  // percent_md: number
  percent_cc: number
  average_score: number
}
export interface IOrderByMonthItemTotal extends IOrderByMonthItem {
  workOrdersSum: string
  // percent_md: string
  percent_cc: string
  average_score: string
  sum: string
}

export type IOrdersForUnitByMonth = { [month: string]: Record<string, OrdersForUnit> }

/** [Месяц] - [Менеджер/Офис] - Сделка[] */
export type IOrdersByManager = Record<string, {orders: Record<string, IOrderByUnitItem[]>, total: number}>
/** [Месяц] - Сумма сделок для юнита[] */
export interface IOrdersByMonth {
  [month: string]: {
    units: IOrderByMonthItemCalc[]
    total: IOrderByMonthItemTotal
  }
}

export type IOrdersByManagerColumns = ColumnsType<IOrderByUnitItem>
export type IOrdersByMonthColumns = ColumnsType<IOrderByMonthItem>

export interface OrderItemResponse {
  'parent-id': number
  'case-date-fact': string
  'order-date-fact': string
  office: string | null
  'manager-name': string | null
  'manager-id': string | null
  'order-responsiples': string | null
  'client-name': string | null
  'client-phone': string | null
  'stage-code': string | null
  'production-order-number': string | null
  'cancel-title': string | null
  'source-appeal': string | null
  'order-attribute': string | null
  city: string | null
  'measurement-responsiples': string | null
  'measurement-date-fact': string
  'mounting-responsiples': string | null
  'mounting-date-fact': string | null
  brand: string | null
  'garbage-remove': string | null
  'agent-commission': number | null
  mounting: number | null
  demounting: number | null
  delivery: number | null
  task_date: string | null
  'products-price': number
  'contract-sum': number | null
  yaClientId: string | null
  campaign: string | null
  medium: string | null
  source: string | null
  content: string | null
  term: string | null
  'delivery-date-fact': string | null
  prepayment_date: string | null
  repayment_date: string | null
  square: string | null
  'delivery-responsiples': string | null
  'audit-date-fact': string | null
  'audit-responsiples': string | null
  'manufacture-date-fact': string | null
  'manufacture-responsiples': string | null
  isCC?: boolean
}
