import React, { memo, useState } from 'react'
import { Table, Spin, Alert } from 'antd'

interface ICustomer {
  id: number
  name: string
}

interface ResultTableProps {
  data?: ICustomer[]
  loading: boolean
  appealId?: string | number
  customerId?: string | number
  warningContent?: string | JSX.Element
  onSelect: (id: number) => void
}

export const ResultTable: React.FC<ResultTableProps> = memo((props: ResultTableProps) => {
  const {
    data,
    loading,
    onSelect,
  } = props

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'ФИО',
      dataIndex: 'name',
      key: 'name',
    }
  ]

  return (
    <div style={{ marginTop: 20 }}>
      <Spin spinning={loading}>
        {data && data?.length > 0 ? (
          <Table
            dataSource={data}
            columns={columns}
            rowKey='id'
            onRow={record => ({ onClick: () => onSelect(record?.id) })}
          />
        ) : (
          <Alert
            type={'warning'}
            description={'Совпадений не найдено'}
          />
        )}
      </Spin>
    </div>
  )
})
