import React, {useEffect, useMemo, useState} from 'react'
import {Button, Col, Layout, Row, Skeleton} from 'antd'
import YearFilter from '../year-filter/year-filter';
import MonthFilter from '../month-filter/month-filter';
import OrdersByManagersTable from '../orders-by-managers-table/orders-by-managers-table';
import {useFetchData} from '../../model/hooks/useFetchData';
import B2BLayout from "../../../../layouts/b2b";
import {FiltersContainer, MapContent, MapTitle, MapWrapper, TableContainer, TableTitle, TaskWrapper} from '../styles';
// import OrganizationFilter from "../organization-filter/organization-filter";
import {HStack} from "../../../../ui/Stack";
import {CircleTemplate} from "../circle-template/circle-template";
import {monthOrder} from "../../model/consts/monthOrder";
import OrdersByMonthTable from "../orders-by-month-table/orders-by-month-table";
import BrandFilter from "../brand-filter/brand-filter";
import OfficeFilter from "../office-filter/office-filter";
import {useSelector} from "react-redux";
import {getRole} from "../../model/selectors/store";
import ManagerFilter from "../manager-filter/manager-filter";

const {Content, Sider} = Layout

const breadcrumbs =
    [{
        href: '/orders',
        title: 'Заказы',
    }]


export default function AdminNewObjectPage() {
    const role = useSelector(getRole);
    const [selectedYears, setSelectedYears] = useState<string[]>([])
    const [selectedMonths, setSelectedMonths] = useState<string[]>([])
    const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])
    const [selectedOffices, setSelectedOffices] = useState<string[]>([])
    const [selectedBrands, setSelectedBrands] = useState<string[]>([])
    const [selectedManagers, setSelectedManagers] = useState<number[]>([])

    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)

    const {
        years,
        organizations,
        offices,
        managers,
        brands,
        ordersByManager,
        ordersByManagerColumns,
        ordersByMonth,
        ordersByMonthColumns,
        isLoading,
        refetchData,
    } = useFetchData(
        selectedYears,
        selectedMonths,
        selectedOrganizations,
        selectedOffices,
        selectedBrands,
        selectedManagers,
    )

    useEffect(() => {
        if (years.length > 0 && isFirstLoad) {
            const currentYear = new Date().getFullYear().toString()
            setSelectedYears([currentYear])
            setIsFirstLoad(false)
        }
    }, [years, isFirstLoad])

    const handleYearChange = (years: string[]) => {
        setSelectedYears(years)
    }

    const handleMonthChange = (months: string[]) => {
        setSelectedMonths(months)
    }

    const handleOrganizationChange = (organizations: string[]) => {
        setSelectedOrganizations(organizations)
    }

    const handleOfficeChange = (offices: string[]) => {
        setSelectedOffices(offices)
    }

    const handleBrandChange = (brands: string[]) => {
        setSelectedBrands(brands)
    }

    const handleManagerChange = (managers: number[]) => {
        setSelectedManagers(managers)
    }

    const handleClearAllFilters = () => {
        const currentYear = new Date().getFullYear().toString()
        setSelectedYears([currentYear])
        setSelectedMonths([])
        setSelectedOrganizations([])
        setSelectedOffices([])
        setSelectedBrands([])
        setSelectedManagers([])
    }


    return (
        <B2BLayout breadcrumbs={breadcrumbs}>
            <MapWrapper>
                <TaskWrapper>
                    <MapTitle>Продажи</MapTitle>
                </TaskWrapper>
                <Layout>
                    <Content style={{padding: '20px'}}>
                        <Row gutter={[16, 16]}>
                            {/*<Col span={24}>*/}
                            {/*    <MapContent>*/}
                            {/*        <HStack>*/}
                            {/*            <CircleTemplate percent={10}/>*/}
                            {/*            <CircleTemplate percent={50}/>*/}
                            {/*            <CircleTemplate percent={185}/>*/}
                            {/*        </HStack>*/}
                            {/*    </MapContent>*/}
                            {/*</Col>*/}
                            <Col span={24}>
                                <MapContent>
                                    <TableTitle>Воронка продаж по-месячно</TableTitle>
                                    <TableContainer>
                                        {isLoading ? (
                                            <Skeleton active/>
                                        ) : (
                                            <OrdersByMonthTable
                                                data={ordersByMonth}
                                                columns={ordersByMonthColumns}
                                            />
                                        )}
                                    </TableContainer>
                                </MapContent>
                            </Col>
                            <Col span={24}>
                                <MapContent>
                                    <TableTitle>Продажи по менеджерам</TableTitle>
                                    <TableContainer>
                                        {isLoading ? (
                                            <Skeleton active/>
                                        ) : (
                                            <OrdersByManagersTable
                                                data={ordersByManager}
                                                columns={ordersByManagerColumns}
                                            />
                                        )}
                                    </TableContainer>
                                </MapContent>
                            </Col>
                        </Row>
                    </Content>
                    <Sider width={300} style={{background: '#f0f2f5', padding: '20px'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <h3>Фильтры</h3>
                            <Button onClick={handleClearAllFilters} type="default">
                                Очистить все
                            </Button>
                        </div>
                        { Boolean(role) && <FiltersContainer>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <YearFilter
                                        selectedYears={selectedYears}
                                        onYearChange={handleYearChange}
                                        years={years}
                                    />
                                </Col>
                                <Col span={24}>
                                    <MonthFilter
                                        selectedMonths={selectedMonths}
                                        onMonthChange={handleMonthChange}
                                        months={monthOrder}
                                    />
                                </Col>
                                {/*<Col span={24}>
                                    <OrganizationFilter
                                        selectedOrganizations={selectedOrganizations}
                                        onOrganizationChange={handleOrganizationChange}
                                        organizations={organizations}
                                    />
                                </Col>*/}
                                {(role === 'go') && <Col span={24}>
                                    <BrandFilter
                                        selectedBrands={selectedBrands}
                                        onBrandChange={handleBrandChange}
                                        brands={brands}
                                    />
                                </Col>}
                                {(role === 'go') && <Col span={24}>
                                    <OfficeFilter
                                        selectedOffices={selectedOffices}
                                        onOfficeChange={handleOfficeChange}
                                        offices={offices}
                                    />
                                </Col>}
                                {(role === 'go' || role === 'd') && <Col span={24}>
                                    <ManagerFilter
                                        selectedManagers={selectedManagers}
                                        onManagerChange={handleManagerChange}
                                        managers={managers}
                                    />
                                </Col>}
                            </Row>
                        </FiltersContainer>}
                    </Sider>
                </Layout>
            </MapWrapper>
        </B2BLayout>
    )
}