import React, {memo, useMemo} from 'react';
import './styles.css';
import {IOrdersByManager, IOrdersByManagerColumns} from "../../model/types/types";
import {Table, Typography} from "antd";
import {HStack} from "../../../../ui/Stack";
import {formatPrice} from "../../../utils";

const {Title} = Typography;

export interface OrdersByManagersTableProps {
    data?: IOrdersByManager
    columns?: IOrdersByManagerColumns
}

const OrdersByManagersTable = (props: OrdersByManagersTableProps) => {
    const {data, columns} = props;
    if (!data || !columns) {
        return <Typography.Text>Сервер не может найти запрошенный ресурс. </Typography.Text>;
    }
    const months = useMemo(() => Object.keys(data), [data]);

    return (
        <div>{
            months.map((month) => (
                    <Table
                        title={() => <Title level={5}>{month}</Title>}
                        key={month}
                        dataSource={Object.values(data[month].orders).flat()}
                        columns={columns}
                        pagination={false}
                        bordered
                        size="small"
                        style={{marginBottom: '40px'}}
                        className='orders-by-managers-table'
                        rowClassName={(record, i) => {
                          
                          return record?.manager?.includes('Итог') ? 'row-bold' : ''
                        }}
                        footer={(currentPageData) => (
                            <HStack justify={"between"} align={"start"} max>
                                <span style={{fontWeight: 'bolder'}}> Всего:</span>
                                <span style={{fontWeight: 'bolder'}}>
                                       {
                                           formatPrice(data[month].total)
                                       }
                            </span>
                            </HStack>
                        )}
                    />
                )
            )
        }</div>
    )
}

export default memo(OrdersByManagersTable);

