import styled from 'styled-components'
import { Select as AntSelect } from 'antd'
import { IStyledProps } from './interface'

interface ICustomSelectProps extends IStyledProps {
  multiline?: boolean;
}

export const Select = styled(AntSelect)<ICustomSelectProps>`
  ${props => Number.isInteger(props?.width) ? 'width: ' + props.width + 'px;' : '' }

  background-color: #fff;

  .ant-select-selector {
    background-color: inherit !important;
  }
  
  ${props => props.multiline && `
    .ant-select-selector {
      height: auto !important;
    }
    
    .ant-select-selection-item {
      white-space: normal !important;
      line-height: 20px !important;
    }
  `}
`